import React from "react";
import { router } from "dva";
import { Affix, Menu, Modal, Badge, Select } from "antd";
import { BellFilled } from "@ant-design/icons";

import { template, setLocalStorage, getLocalStorage, getObjectProp } from "@reco-m/core";
import { changeUrlParams, ViewComponent, mtaH5Click, statisticsEvent, getParkCode, InputDelay } from "@reco-w/core-ui";

// import { IS_OPEN_ENTERPRISE_HOME, IS_OPEN_RECRUIT } from "@reco-w/common-common";
import { Namespaces, headerModel, getStatisticArticle } from "@reco-w/layout-models";

const { Option } = Select;
const { SubMenu } = Menu;
export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, headerModel.StateType {
        navState?: any;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        excludeSplit = ["/policy?"];

        get logoutRouter() {
            return getObjectProp(client, "logoutRouter", "/login");
        }

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        componentDidMount() {

            this.dispatch({
                type: "initPage",
                inputData: { radioSelect: getLocalStorage("parkId") },
                judgeParkCode: this.parkcode,
            });
            $(window).scroll(() => {
                let top = $(window).scrollTop() as any;
                top >= 83
                    ? this.setState({
                        navState: true,
                    })
                    : this.setState({
                        navState: false,
                    });
            });
        }

        componentReceiveProps(nextProps: P) {
            let isLogin =
                (nextProps.location?.search?.includes("isLogin=") && !this.props.location?.search?.includes("isLogin=")) ||
                (this.props.location?.search?.includes("isLogin=") && !nextProps.location?.search?.includes("isLogin="));

            if (isLogin) {
                this.dispatch({ type: "getCertify" });
            }

            if (nextProps.location?.hash?.includes(this.logoutRouter)) {
                this.goTo(this.logoutRouter);
                window.location.reload();
            }
        }

        componentWillUnmount() {
            this.setState = () => {
                return;
            };
        }

        /**
         * 切换园区
         * @param park
         */
        changePark(park: any) {
            if (park) {
                mtaH5Click(statisticsEvent.parkSwitch);
                setLocalStorage("parkId", park.value);
                setLocalStorage("parkName", park.label);
                setLocalStorage("parkCode", park.code);
                setLocalStorage("selectedMenu", "/home"); // 设置被选中的菜单为home
                this.dispatch({
                    type: "input",
                    data: { radioSelect: park.value, parkName: park.label, parkId: park.value },
                });
                if (this.isAuth()) {
                    this.dispatch({ type: "getCheckInDetail", message: this.message });
                    this.dispatch({ type: "memberCommon/cleanMemberInfo" }); // 切换园区清除原来认证信息
                    this.dispatch({ type: "memberCommon/cleanLoyalty" }); // 切换园区清除原来积分信息
                }
                this.goTo(`/${park.code}`);
            }
        }

        /**
         * 渲染已登录时的展示按钮
         * @returns auth login
         */
        renderAuthLogin(): React.ReactNode {
            const { state } = this.props,
                user = state!.user;
            return (
                <>
                    <router.Link
                        to={`/${getParkCode()}/my`}
                        onClick={() => {
                            setLocalStorage("selectedMenu", "/my");
                            setLocalStorage("mySelectedMenu", "/my");
                        }}
                    >
                        {user?.nickName || "请设置昵称"}
                    </router.Link>
                    <span className="mh6">丨</span>
                    <router.Link
                        to={`/${getParkCode()}/login`}
                        onClick={() =>
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/${getParkCode()}/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            })
                        }
                    >
                        注销
                    </router.Link>
                </>
            );
        }

        /**
         * 渲染未登录时的展示按钮
         * @returns unauth login
         */
        renderUnauthLogin(): React.ReactNode {
            return (
                <>
                    <router.Link to={`/${getParkCode()}/login`}> 登录</router.Link>
                    <span className="mh6">丨</span>
                    <router.Link to={`/${getParkCode()}/login/register`}>注册</router.Link>
                </>
            );
        }

        /**
         * 渲染登录按钮
         * @returns
         */
        renderLogin(): React.ReactNode {
            return this.isAuth() ? this.renderAuthLogin() : this.renderUnauthLogin();
        }

        /**
         * 渲染消息数量
         */
        renderNotice() {
            const { state } = this.props,
                noticeCount = state!.noticeCount;
            return this.isAuth() ? (
                <a className="margin-right-xs" onClick={() => this.goTo(`/${this.parkCode}/my/mynotice`)}>
                    <BellFilled />
                    <Badge className="ml5" count={noticeCount} size="small"></Badge>
                </a>
            ) : null;
        }

        /**
         * 渲染搜索栏
         * @returns
         */
        renderSearch(): React.ReactNode {
            const { state } = this.props,
                searchKey = state!.searchKey;
            return (
                <div className="home-search ml25">
                    <InputDelay.Component
                        placeholder="输入关键字搜索"
                        style={{ width: 220 }}
                        value={searchKey}
                        onChange={(e) => this.dispatch({ type: "input", data: { searchKey: e.target.value } })}
                        onDelayTrigger={(value) => this.searchKey(value)}
                        hideIcon
                    />
                    <a>
                        <i className="icon iconsousuo"></i>
                    </a>
                </div>
            );
        }

        /**
         * 渲染园区选择
         * @returns parks
         */
        renderParks(): React.ReactNode {
            const { state } = this.props,
                parkNames = state!.parkNames || [],
                radioSelect = getLocalStorage("parkId") || state!.radioSelect;
            return (
                parkNames.length > 0 && (
                    <Select  dropdownClassName="park-select-option" value={radioSelect} dropdownMatchSelectWidth={false} bordered={false} onChange={(e) => this.changePark(parkNames.find((x) => x.value === e))}>
                        {parkNames.map((item, i) => (
                            <Option value={item.value} key={i}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                )
            );
        }

        /**
         * 获取选中的目录
         * @param menuKey
         * @returns
         */
        getSelectedKeys(menuKey) {
            if (menuKey) {
                return [menuKey];
            }

            const selectedMenuText = getLocalStorage("selectedMenu");

            let selectedMenu = selectedMenuText;


            if (!this.excludeSplit.some((x) => selectedMenuText?.includes(x))) {
                let selectedMenus = selectedMenuText && selectedMenuText.split("?");
                selectedMenu = (selectedMenus && selectedMenus.length > 0 && selectedMenus[0]) || "";
            }

            return [selectedMenu || ""];
        }

        /**
         * 渲染单条目录样式
         * @param key
         * @param link
         * @param event
         * @param title
         * @param [flag]
         * @returns menu item
         */
        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            return (
                <Menu.Item key={key}>
                    <router.Link
                        to={link}
                        className={flag ? "size-16" : ""}
                        onClick={() => {
                            mtaH5Click(event);
                        }}
                    >
                        {title}
                    </router.Link>
                </Menu.Item>
            );
        }

        /**
         * 渲染下拉目录标题
         * @param title
         * @returns
         */
        renderSubMenuTitle(title) {
            return <span className="size-16">{title}</span>;
        }

        /**
         * 点击目录时
         * @param e
         */
        onClickMenu(e) {
            if (e?.keyPath?.length > 0 && e.keyPath[0].includes("?")) {
                this.dispatch({ type: "input", data: { menuKey: e.keyPath[0] } });
            } else {
                this.dispatch({ type: "input", data: { menuKey: null } });
            }
        }
        /**
         * 渲染目录栏
         * @returns
         */
        renderMenu(): React.ReactNode {
            const { state } = this.props,
                menuKey = state!.menuKey,
                parkCode = getParkCode(),
                articleCatalogs = state!.articleCatalogs,
                selectedKeys: any[] = this.getSelectedKeys(menuKey);
            // openEnterprise = IS_OPEN_ENTERPRISE_HOME,
            // openRecruit = IS_OPEN_RECRUIT;
            // console.log(articleCatalogs)
            let newArticleCatalogs;
            if (articleCatalogs) {
                newArticleCatalogs = articleCatalogs.filter(item => {
                    if (item.catalogCode === "RCGY" || item.catalogCode === "RQBJ") { // 暂时去除人才公寓和入企保洁
                        return false
                    }
                    return true
                })
            }


            return (
                <Menu selectedKeys={selectedKeys} mode="horizontal" className="index-header" onClick={(e) => this.onClickMenu(e)}>
                    {this.renderMenuItem("/home", `/${parkCode}`, statisticsEvent.navigationBarHome, "首页", true)}
                    {this.renderMenuItem("/party", `/${parkCode}/party`, statisticsEvent.navigationBarHome, "党建服务", true)}

                    <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("园区招商")}>
                        {this.renderMenuItem("/impression", `/${parkCode}/impression`, statisticsEvent.navigationBarImpression, "园区招商")}
                        {this.renderMenuItem("/investment", `/${parkCode}/investment`, statisticsEvent.navigationBarCircle, "招商引资")}
                    </SubMenu>
                    <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("新闻动态")}>
                        {newArticleCatalogs?.length > 0 &&
                            newArticleCatalogs.map((item) =>
                                this.renderMenuItem(
                                    `/article/${item.catalogCode}`,
                                    `/${parkCode}/article/${item.catalogCode}`,
                                    getStatisticArticle(item.catalogCode, "navigationBar"),
                                    item.catalogName
                                )
                            )}
                        {/* {this.renderMenuItem("/circle", `/${parkCode}/circle`, statisticsEvent.navigationBarCircle, "话题动态")} */}
                    </SubMenu>

                    <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("园区服务")}>
                        {this.renderMenuItem("/activity", `/${parkCode}/activity`, statisticsEvent.navigationBarActivity, "活动中心")}
                        {this.renderMenuItem("/serviceentrance", `/${parkCode}/serviceentrance`, statisticsEvent.navigationBarAllMarket, "园区服务")}
                        {this.renderMenuItem("/propertyentrance", `/${parkCode}/propertyentrance`, statisticsEvent.navigationBarApplication, "物业服务")}
                        {/* {this.renderMenuItem("/article/RQBJ",`/${parkCode}/article/RQBJ`,getStatisticArticle("RQBJ", "navigationBar"),"专业服务")} */}
                        {/*{this.renderMenuItem("/workorder/wybx", `/${parkCode}/workorder/wybx`, statisticsEvent.navigationBarApplication, "物业报修")}
                        {this.renderMenuItem("/survey", `/${parkCode}/survey`, statisticsEvent.navigationBarResearch, "问卷调研")}
                        {this.renderMenuItem("/workorder/xqtb", `/${parkCode}/workorder/xqtb`, statisticsEvent.navigationBarDemand, "需求提报")}
                        {this.renderMenuItem("/workorder/wyts", `/${parkCode}/workorder/wyts`, statisticsEvent.navigationBarComplaint, "物业投诉")}
                        {this.renderMenuItem("/market/all", `/${parkCode}/market/all`, statisticsEvent.navigationBarAllMarket, "专业服务")}*/}
                    </SubMenu>
                    <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("资源预订")}>
                        {this.renderMenuItem("/resource/roommeet", `/${parkCode}/resource/roommeet`, statisticsEvent.navigationBarMeetingroom, "会议室预订")}
                        {this.renderMenuItem("/resource/hall", `/${parkCode}/resource/hall`, statisticsEvent.navigationBarMeetingroom, "展厅预订")}
                        {this.renderMenuItem("/resource/gymnasium", `/${parkCode}/resource/talentapartment`, statisticsEvent.navigationBarMeetingroom, "人才公寓预订")}
                        {/* {this.renderMenuItem("/resource/gymnasium", `/${parkCode}/resource/gymnasium`, statisticsEvent.navigationBarMeetingroom, "健身房预订")} */}

                    </SubMenu>

                    {/* <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("政策快查")}> */}
                    {/* {this.renderMenuItem("/policy?ti=2", `/${parkCode}/policy?ti=2`, statisticsEvent.navigationBarPolicy, "政策匹配")}
                        {this.renderMenuItem("/policy?ti=3", `/${parkCode}/policy?ti=3`, statisticsEvent.navigationBarPolicy, "政策计算器")} */}
                    {/* </SubMenu> */}

                    {this.renderMenuItem("/policyo?ti=1", `/${parkCode}/policyo?ti=1`, statisticsEvent.navigationBarPolicy, "政策快查", true)}


                    {/* <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("企业服务")}>
                        {this.renderMenuItem("/product/all", `/${parkCode}/product/all`, statisticsEvent.navigationBarAllMarket, "服务产品")}
                        {openEnterprise && this.renderMenuItem("/enterprise", `/${parkCode}/enterprise`, statisticsEvent.navigationBarCircle, "企业主页")}
                        {openRecruit && this.renderMenuItem("/recruit", `/${parkCode}/recruit`, statisticsEvent.navigationBarCircle, "企业招聘")}
                    </SubMenu> */}
                    {this.renderMenuItem("/my", `/${parkCode}/my`, statisticsEvent.navigationBarMy, "个人中心", true)}
                </Menu>
            );
        }

        /**
         * 关键字搜索
         * @param value
         */
        searchKey(value: string) {
            if (value && value.trim()) {
                mtaH5Click(statisticsEvent.globalSearch);
                this.goTo(changeUrlParams(`/${getParkCode()}/heardsearch`, { gk: value }));
            } else {
                if (location.pathname.indexOf("heardsearch") > -1) this.goTo(`/${getParkCode()}`);
                else window.location.reload();
            }
        }

        renderBodyParkList() {
            const { state } = this.props,
                parkNames = state!.parkNames || [],
                radioSelect = getLocalStorage("parkId") || state!.radioSelect;
            return (
                parkNames.length > 0 && (
                    <Select dropdownClassName="park-select-option"  value={radioSelect} dropdownMatchSelectWidth={false} bordered={false} onChange={(e) => this.changePark(parkNames.find((x) => x.value === e))}>
                        {parkNames.map((item, i) => (
                            <Option value={item.value} key={i}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                )
            );
        }

        render(): React.ReactNode {
            return (
                <Affix offsetTop={0}>
                    <div className="nav new-nav">
                        {/* <img src={"assets/images/baod/logo.png"} /> */}
                        <img src={"assets/images/bd-logo1.png"} />
                        <div className="park-box">{this.renderBodyParkList()}</div>
                        <div className="container">{this.renderMenu()}</div>
                        <div className="content-right">
                            {this.renderLogin()}
                            {this.renderSearch()}
                        </div>
                    </div>
                </Affix>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
